.relevance-item {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
  text-transform: capitalize;

  &.High,
  &.Medium,
  &.Low {
    color: var(--custom-color-white);
  }

  &.High {
    background-color: var(--ion-color-success);
  }
  &.Medium {
    background-color: var(--ion-color-warning);
  }
  &.Low {
    background-color: var(--ion-color-danger);
  }
}
