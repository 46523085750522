.therapies-trials-table {
  background-color: var(--custom-color-white);
  padding: 20px 10px;
  border-radius: 10px;
  text-align: left;
  table {
    width: 100%;
  }
  .ttt-header {
    border-bottom: 1px solid var(--ion-color-light-shade);
    font-size: 12px;
    text-transform: uppercase;
    color: var(--ion-color-medium);
    th {
      padding: 0 5px;
    }
    th:last-child {
      text-align: right;
    }
  }
  .ttt-notes {
    padding-top: 10px;
  }
  .ttt-show-more {
    margin-top: 20px;
    width: fit-content;
  }
}
