@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0px;
}

.modal-full-size {
  --height: 100%;
  --width: 100%;
  --border-radius: 0;
}

ion-button {
  --box-shadow: 0;
  --border-width: 1px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
