.signup-form {
  p {
    text-align: center;
  }
  .signup-form-therms {
    margin: 24px 0;
    color: var(--ion-text-color);
  }
  .signup-form-login-link {
    margin-top: 20px;
    color: var(--ion-text-color);
  }
}
