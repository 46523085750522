#modal-explore-header {
  ion-icon {
    &.starred {
      color: var(--ion-color-primary);
    }
  }
}
#modal-explore-article-iframe {
  iframe {
    height: 100vh;
    width: 100vw;
  }
}
.article-rendering {
  padding: 24px 60px;
  .pmid {
    font-size: 14px;
    color: var(--ion-color-medium);
  }
  .ar-title {
    font-size: 20px;
    margin: 10px 0 24px 0;
    line-height: 1.2;
  }
  .abstract-title {
    font-size: 16px;
    text-transform: uppercase;
  }
  .abstract-description {
    font-size: 16px;
    line-height: 1.4em;
  }
}
