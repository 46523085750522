.report-page-viewport {
  padding: 24px;
  .underscore-text {
    text-decoration: underline;
    margin-left: 4px;
  }
}

.progress-note {
  margin-bottom: 20px;
}

.reply-to-oncologist {
  display: flex;
  padding: 10px;
  justify-content: center;
  background-color: var(--ion-color-light-tint);
  ion-button {
    width: fit-content;
  }
}
