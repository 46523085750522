.chart-info-item {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  font-size: 12px;
  .chart-info-ball {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    &.mutation {
      background-color: var(--custom-color-chart-blue);
    }
    &.amplification {
      background-color: var(--custom-color-chart-pink);
    }
  }
}
