.viewport {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
}

.viewport-print {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
