.header-toolbar {
  height: 64px;
  display: flex;
  border-bottom: 1px solid var(--ion-color-light-shade);
  align-items: center;
  justify-content: space-around;
  .header-toolbar-logo {
    flex: 1;
    padding: 16px 24px;
    font-size: 24px;
    .logo {
      cursor: pointer;
    }
    .blue {
      color: var(--ion-color-primary);
    }
  }
  .header-toolbar-segment {
    display: flex;
    padding: 0px 24px;
    ion-button {
      padding: 0 16px;
    }
  }
  .header-toolbar-popover {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0px 16px;
    border-left: 1px solid var(--ion-color-light-shade);
    height: 100%;
  }
}
