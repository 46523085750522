.genomic-variants {
  margin: 20px 0;
  border-radius: 8px;
  background-color: var(--ion-color-light);
  padding: 16px;
  .gv-custom-title {
    margin-bottom: 10px;
  }
  .gv-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .gv-processing {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.gv-show-more ion-button {
  width: fit-content;
  margin-top: 20px;
}
