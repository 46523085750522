.pti-item {
  margin-bottom: 10px;
  .pti-title {
    font-weight: bold;
    display: block;
  }
  .pti-detail-item {
    display: block;
    font-size: 12px;
    color: var(--ion-color-medium);
  }
}

.ptt-wrapper {
  .ptt-empty {
    color: var(--ion-color-medium);
    padding: 20px 16px;
    text-align: center;
    display: block;
    font-weight: bold;
  }
}
