.barchart {
  display: grid;
  grid-template-columns: 30% calc(70% - 10px);
  align-items: center;
  gap: 10px;
}

.itemLabel {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.barChartItem {
  position: relative;
  .itemContent {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
    position: relative;
    z-index: 2;
    font-size: 10px;
  }
  .itemBarChart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    .percentage1,
    .percentage2 {
      height: 100%;
    }
    .percentage1 {
      background-color: var(--custom-color-chart-blue);
    }

    .percentage2 {
      background-color: var(--custom-color-chart-pink);
    }
  }
}
