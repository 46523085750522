.not-found-explore {
  margin: 30px auto;
  color: var(--ion-color-medium);
  text-align: center;
  p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
}
