.select-default {
  align-items: center;
  border-bottom: 1px solid var(--ion-text-color);
  border-radius: 8px 8px 0px 0px;
}
ion-select::part(icon) {
  content: url('../../../../public/assets/arrow-down.svg');
  opacity: 1;
  padding: 0 6px;
}
