.latest-report-card {
  .icon-wrapper {
    background-color: var(--ion-color-light);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    .icon {
      background-color: var(--ion-color-primary);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      font-size: 20px;
      color: var(--custom-color-white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .text-block {
    display: block;
    &.pretitle {
      color: var(--ion-color-medium);
      font: 14px;
    }
    &.lrc-title {
      font-weight: bold;
    }
    &.subtitle {
      font: 14px;
      margin-bottom: 10px;
    }
  }
}
