.explore-tabs {
  height: 45px;
  background: linear-gradient(to left, var(--ion-color-primary), var(--ion-color-secondary));
  .explore-tabs-viewport {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 45px;
  }
  .selected-tab {
    color: var(--ion-color-primary);
    background-color: var(--custom-color-white);
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .tab {
    text-align: center;
    cursor: pointer;
    &:not(.selected-tab) {
      color: var(--custom-color-white);
    }
    &:not(:last-of-type) {
      border-right: 1px solid var(--custom-color-white);
    }
    .tab-chip {
      background-color: var(--ion-color-secondary);
      border-radius: 4px;
      color: var(--custom-color-white);
      padding: 4px 8px;
    }
  }
}
