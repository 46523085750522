.patient-page {
  ion-content {
    --background: var(--custom-color-grey);
  }
  .viewport-patient-information-page {
    padding: 0 36px;
  }
  .patient-information-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: -180px;
    .default-card {
      margin-bottom: 20px;
    }
  }
}
