.ttt-row {
  border-bottom: 1px solid var(--ion-color-light-shade);
  td {
    padding: 10px 5px;
    &.selectable {
      display: flex;
      align-items: center;
    }
    &:not(.ttt-therapy) {
      font-size: 12px;
      color: var(--ion-color-medium);
    }
  }
  th {
    &:last-child {
      text-align: right;
    }
  }
  .tttt-relevant {
    color: var(--ion-color-medium);
  }
  .ttt-actions {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
  .ttt-checkbox {
    min-width: 20px;
    max-width: 20px;
    margin-right: 16px;
  }
  .link-title-table {
    color: inherit;
    text-decoration: none;
  }
}
