.box-wrapper {
  display: grid;
  grid-template:
    'a' 300px
    'b' 9fr;
  height: 100%;
  .box-top {
    background-color: var(--ion-color-primary);
    display: flex;
    justify-content: center;
    background-image: url('../../../../public/assets/dna.svg'),
      linear-gradient(to right, var(--ion-color-primary), var(--ion-color-secondary));
    background-repeat: no-repeat;
    .logo {
      margin-top: 100px;
      color: var(--custom-color-white);
      font-size: 48px;
      font-weight: 700;
    }
  }
  .box-bottom {
    display: flex;
    justify-content: center;
  }
}
