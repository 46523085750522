.patients-list {
  padding: 0 24px;
  table {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin-top: -35px;
    border-collapse: collapse;
    border-spacing: 0;
    ion-spinner {
      font-size: 24px;
      text-align: center;
      padding: 36px 0;
      width: 100%;
      background: var(--custom-color-white);
    }
  }
  .patients-list-header {
    background: var(--ion-color-secondary);
    color: var(--custom-color-white);
    border-radius: 8px 8px 0 0;
    height: 35px;
    th {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      text-align: left;
      padding: 0 20px;
    }
  }
  .patients-list-item-notfound {
    text-align: center;
    td {
      background-color: var(--custom-color-white);
      padding: 48px 0;
    }
  }
}
