.pagination {
  text-align: center;
  margin: 20px 0;
  ion-button {
    --background: var(--ion-color-light-shade);
    --border-radius: 1.5em;
    height: 32px;
    color: var(--ion-color-medium);
  }
}
