.default-input {
  text-align: left;
  border-bottom: 1px solid var(--ion-text-color);
  border-radius: 8px 8px 0px 0px;
  --inner-padding-end: 0;
  .input-label {
    color: var(--ion-text-color);
  }
  .textbox {
    --color: var(--ion-text-color);
  }
  .right-button {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  ion-icon {
    color: var(--ion-color-medium);
  }
  .not-editable {
    padding: 8px 0;
    &.not-filled {
      color: var(--ion-color-step-200);
    }
  }
}
